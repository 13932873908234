import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

export default function CountySelect() {
  const dispatch = useDispatch();
  const { teams } = useSelector((state) => state.teamsInfo);
  const { users } = useSelector((state) => state.userLogin); // users 為物件，包含 team_id

  const handleChange = (event) => {
    const selectedTeamId = event.target.value;
    // 傳送動作更新 users 的 team_id 屬性
    dispatch({ type: 'UPDATE_TEAM_ID', payload: selectedTeamId });
  };

  return (
    <FormControl variant="outlined" size="small" sx={{ width: 150 }}>
      <InputLabel id="county-select-label">選擇縣市</InputLabel>
      <Select
        labelId="county-select-label"
        id="county-select"
        value={users?.team_id || ''}
        onChange={handleChange}
        label="縣市"
      >
        {teams &&
          teams.map((team) => (
            <MenuItem key={team.id} value={team.id}>
              {team.description}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}
